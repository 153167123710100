import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { menusActionCreators } from "store/menus";
import { ordersActionCreators } from "store/orders";
import OrderModifyDialog from "./dialogs/OrderModifyDialog";
import OrderItemAddDialog from "./dialogs/OrderItemAddDialog";
import OrderItemModifiersDialog from "./dialogs/OrderItemModifiersDialog";

const OrderModifyProcessContainer = props => {
	const [showOrderModifyDialog, setShowOrderModifyDialog] = useState(0);	// 1 = open and clear form, 2 = open but leave form (used when "returning" from adding an item)
	const [showOrderItemAddDialog, setShowOrderItemAddDialog] = useState(false);
	const [showOrderItemModifiersDialog, setShowOrderItemModifiersDialog] = useState(false);
	const [itemsToAdd, setItemsToAdd] = useState([]);
	const dispatch = useDispatch();
	const menuStore = useSelector(state => state.menus);

	useEffect(() => {
		if(props.orderModifyProcessRunning) {
			setShowOrderModifyDialog(1);
			setItemsToAdd([]);
		}
	}, [props.orderModifyProcessRunning]);

	const hideOrderModifyDialog = (e, formData) => {
		// console.log('hideOrderModifyDialog returned:', e?.currentTarget.id, formData);
		switch(e?.currentTarget.id) {
			case 'btnAdd': {
				dispatch(menusActionCreators.fetchOrderMenus(true));	// can't rely on the cache as menu might have been edited
				setShowOrderItemAddDialog(true);
				break;
			}
			case 'btnOk': {	// MODIFY ORDER selected
				dispatch(ordersActionCreators.updateOrder(props.order, itemsToAdd, formData));
				break;
			}
			default: break;
		}
		setShowOrderModifyDialog(0);
		props.setOrderModifyProcessRunning(false);
	}

	const removeAddedItem = ix => {
		console.log(itemsToAdd);
		const items = [...itemsToAdd];
		items.splice(ix, 1);
		setItemsToAdd(items);
	}

	const resetOrder = () => {
		// unmark all items marked for removal
		if(props.order) {
			props.order.orderItems.forEach(item => {
				delete(item.delete);
			});
		}
	}

	const hideOrderItemAddDialog = newItem => {
		setShowOrderItemAddDialog(false);
		if(newItem.item) {
			setItemsToAdd([...itemsToAdd, newItem]);
			if(newItem.item.modifierGroups.length) {
				setShowOrderItemModifiersDialog(true);
			} else {
				setShowOrderModifyDialog(2);
			}
		} else {
			setShowOrderModifyDialog(2);
		}
	}

	const hideOrderItemModifiersDialog = obj => {
		setShowOrderItemModifiersDialog(false);
		if(Array.isArray(obj)) {
			const updatedItemsToAdd = [...itemsToAdd];
			const lastAddedItemIndex = itemsToAdd.length - 1;
			const item = itemsToAdd[lastAddedItemIndex].item;
			item.modifierItems = obj;
			console.log(updatedItemsToAdd);
			setItemsToAdd(updatedItemsToAdd);
			setShowOrderModifyDialog(2);
		}
		// console.log('hideOrderItemModifiersDialog:', obj);
	}

	return (
		<Fragment>

			<OrderModifyDialog
				show={ showOrderModifyDialog }
				order={ props.order }
				itemsToAdd={ itemsToAdd }
				currencySymbol={ props.currencySymbol }
				onHide={ hideOrderModifyDialog }
				setOrder={ props.setCurrentOrder }
				removeAddedItem={ removeAddedItem }
				onShow={ resetOrder }
			/>

			<OrderItemAddDialog
				show={ showOrderItemAddDialog }
				menus={ menuStore.orderMenus }	// for selection
				items={ menuStore.orderItems }	// for search
				currencySymbol={ props.currencySymbol }
				onHide={ hideOrderItemAddDialog }
			/>

			<OrderItemModifiersDialog
				show={ showOrderItemModifiersDialog }
				item={ itemsToAdd.length ? itemsToAdd[itemsToAdd.length - 1].item : null }
				currencySymbol={ props.currencySymbol }
				onHide={ hideOrderItemModifiersDialog }
			/>

		</Fragment>
	);
};

export default OrderModifyProcessContainer;
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import utilities from 'services/utilities';
import useL10n from 'L10n';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import { Pane, PaneBody, PaneHeader, PaneItem } from 'components/Pane';
import useForm from 'components/Form';
import { FormDropdown, FormInput, FormLabel } from 'components/formControls';

const OrderItemAddDialog = props => {
	const _t = useL10n().getText;

	const [items, setItems] = useState();

	const form = useForm();
	const formRef = useRef(form);

	useEffect(() => {
		if(props.show) {
			formRef.current.onChange(null, 'qty', 1);
		}
	}, [props.show]);

	useEffect(() => {
		if(typeof form.data.menuId === 'string') {
			formRef.current.onChange(null, 'menuId', parseInt(form.data.menuId, 10));
			formRef.current.onChange(null, 'sectionId', undefined);
			setItems();
		}
		if(typeof form.data.sectionId === 'string') {
			const sectionId = parseInt(form.data.sectionId, 10);
			formRef.current.onChange(null, 'sectionId', sectionId);
			const menu = props.menus.find(menu => menu.menuId === form.data.menuId);
			const section = menu.menuSections.find(section => section.menuSectionId === sectionId);
			setItems(section.menuItems);
			}
	}, [form.data, props.menus]);

	useEffect(() => {
		const search = form.data.search;
		if(search) {
			if(form.data.menuId || form.data.sectionId) {
				formRef.current.reset({ search: search });
				// this will retrigger this effect, so we can filter items next time around
			} else {
				const items = props.items?.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
				setItems(items);
			}
		} else {
			if(!form.data.menuId && !form.data.sectionId) {
				// if search term deleted, clear filtered results
				setItems();
			}
		}
	}, [form.data.search, props.items, form.data.menuId, form.data.sectionId]);

	const getMenuSections = () => {
		if(form.data.menuId && props.menus) {
			const menu = props.menus.find(menu => menu.menuId === form.data.menuId);
			if(menu) {
				return menu.menuSections;
			}
		}
		return null;
	}

	const itemClickHandler = item => {
		if(!item.isInStock) return;
		if(isNaN(form.data.qty) || form.data.qty < 1) {
			return;
		}
		const newItem = {
			qty: form.data.qty,
			item: item
		}
		props.onHide(newItem);
	}

	const renderMenuItems = () => {
		return items.map((item, ix) => {
			if(item.isHidden) return null;
			const classes = classNames({ 'Pane-item_oos': !item.isInStock });
			return (
				<PaneItem key={ ix } className={ classes } disabled={ !!classes } index={ item } onClick={ itemClickHandler }>
					<div className="col-auto">{ item.menuItemId }</div>
					<div className="col">{ item.name }</div>
					<div className="col-auto">{ utilities.formatPrice(item.onPromo && item.promoPrice ? item.promoPrice : item.price, props.currencySymbol) }</div>
				</PaneItem>
			);
		});
	}

	return (
		<Modal id="orderItemAddDialog" show={ props.show } onHide={ props.onHide }>
			<ModalHeader>{ _t('HDG_ORDER_ADD_ITEM', true) }</ModalHeader>
			<ModalBody>

				<div className="form-row">
					<div className="col">
						<FormDropdown
							form={ form }
							field="menuId"
							placeholder="_CHOOSE_MENU"
							disabled={ !!form.data.search }
							options={ props.menus }
							keys="menuId"
							labels="title"
						/>
					</div>
					<div className="col">
						<FormDropdown
							form={ form }
							field="sectionId"
							placeholder="_CHOOSE_SECTION"
							disabled={ !form.data.menuId || !!form.data.search }
							options={ getMenuSections() }
							keys="menuSectionId"
							labels="title"
						/>
					</div>
					<div className="col search-container">
						<FormInput form={ form } field="search" placeholder="_SEARCH"/>
					</div>
				</div>

				{ items && items.length > 0 && 
					<Pane>
						<PaneHeader>Items</PaneHeader>
						<PaneBody tag="ul" className="Pane-items Pane-items_p5 Pane-items_indexed pb5 scrolling">
							{ renderMenuItems() }
						</PaneBody>
					</Pane>
					}

			</ModalBody>
			<ModalFooter className="no-gutters">
				<FormLabel htmlFor="qty" className="col-2" label="_QUANTITY"/>
				<div className="col-2">
					<FormInput placeholder=" " type="number" min="1" form={ form } field="qty"/>
				</div>
				<button className="btn btn-secondary" onClick={ props.onHide } id="btnCancel">
					{ _t('BTN_CANCEL', true) }
				</button>
			</ModalFooter>
		</Modal>
	);
};

export default OrderItemAddDialog;